.invoice-container {
    width: 700px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    font-size: 14px;
  }
  
  .invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .e-way-bill {
    text-align: center;
  }
  
  .e-way-bill h1 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  
  .invoice-details {
    text-align: right;
 
  }
  
  .invoice-details p {
    margin-bottom: 5px;
  }
  
  .address-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .from-address,
  .to-address {
    width: 45%;
  }
  
  .from-address h2,
  .to-address h2 {
    margin-bottom: 10px;
  }
  
  .from-address p,
  .to-address p {
    margin-bottom: 5px;
  }
  
  .goods-details {
    margin-bottom: 20px;
  }
  
  .goods-details table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .goods-details th,
  .goods-details td {
    padding: 5px;
    border: 1px solid #ddd;
  }
  
  .invoice-summary {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .tax-amount,
  .other-amount,
  .total-invoice-amount,
  .cgst-sgst {
    width: 45%;
    margin-bottom: 10px;
  }
  
  .tax-amount p,
  .other-amount p,
  .total-invoice-amount p,
  .cgst-sgst p {
    margin-bottom: 5px;
  }


  .clickable-icon {
    background-color: #d4d0c8;
    border: 1px solid #a7a39e;
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  }
  
  .clickable-icon:hover {
    background-color: #c1bdb3;
  }

  .overlay-canceled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5em; /* Adjust the font size as needed */
    color: red; /* Adjust the color as needed */
    opacity: 0.5; /* Adjust the transparency as needed */
    z-index: 9999; /* Ensure the overlay stays on top */
  }
  