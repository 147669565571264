/* .collapse{
  text-align: center !important;
}
.sidebar .nav .caret{
  position: absolute !important;
  top: 47% !important;
} */

.main-container {
  display: flex;
}

main {
  width: 100%;
  /* margin-top: 4rem; */
  /* padding: 25px;
      padding-top: 4rem; */
  /* overflow: scroll; */
  /* overflow-y: scroll; */
  min-height: 100vh;
  overflow-x: hidden;
  /* background-image: url('../assets/images/background_banner.png'); */
  /* background-size: cover; */
  background-color: var(--main-bg-color);
}

.main-children {
  margin: 8.5rem 1.5rem 1rem 1.5rem;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: var(--sidebar-bg-color);
  color: var(--sidebar-text-color);
  min-height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0px;
  box-shadow: 0px 0px 3px var(--sidebar-text-color);
}

.top_section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0.7rem;
  border-bottom: 0.25rem solid var(--sidebar-text-color);
  cursor: pointer;
  height: 7.9rem;
}

.logo {
  width: 80%;
  margin: auto;
}

.bars {
  font-size: 3rem;
  font-weight: 500;
}

.hide {
  display: none;
}

.routes {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: fixed;
  max-height: 89%;
  overflow: auto;
  overflow-x: hidden;
  /* line-height: 1.6; */
}

.link {
  display: flex;
  align-items: center;
  color: var(--sidebar-text-color);
  gap: 1rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  /* text-transform: uppercase; */
  font-weight: 500;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  line-height: 1.56;
}

.link:hover {
  /* border-right: 4px solid white; */
  background: var(--sidebar-hover-color);
  color: var(--sidebar-bg-color);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
  background: var(--sidebar-module-color);
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.25);
  color: var(--sidebar-bg-color);
}

.link_text {
  white-space: nowrap;
  font-size: 1.78rem;
  padding: 0.6rem 0rem;
  margin-top: 0.5rem;
}

.icon {
  font-size: 2rem;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--sidebar-text-color);
  padding: 1rem 0.5rem 1rem 1rem;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  font-weight: 500;
}
/* .menu:hover{
      background:#7F56D9;
      color: #F2F4F7;
    } */
.activemenu {
  color: var(--sidebar-hover-color);
}

.menu_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 2.2rem;
}

/* scrollbar styling */
.table-container::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
  height: 8px;
}

/* Track (the area behind the thumb) */
.table-container::-webkit-scrollbar-track {
  background-color: #d4cbcb;
}

/* Thumb (the draggable part) */
.table-container::-webkit-scrollbar-thumb {
  background-color: #041f7a;
  border-radius: 5px;
}

/* On hover, style the thumb */
.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #4b60a7;
}

/* Style the scrollbar track (the area where the scrollbar moves) */
.routes::-webkit-scrollbar {
  width: 0.8rem; /* Change the width of the scrollbar */
}

/* Style the scrollbar thumb (the draggable part of the scrollbar) */
.routes::-webkit-scrollbar-thumb {
  background: var(--sidebar-module-color);
  border-radius: 1.5rem; /* Round the edges of the thumb */
}

/* Style the scrollbar track on hover */
.routes::-webkit-scrollbar-thumb:hover {
  background: var(--sidebar-hover-color);
}

/* header section css start */
.bg-body-tertiary {
  position: fixed;
  top: 0px;
  z-index: 3;
  height: 8rem;
  width: 100%;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}

.header-section {
  justify-content: end !important;
  position: relative;
  transition: all 0.3s linear;
}

.main-container {
  right: 20rem;
}

.header-avatar {
  display: flex;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 0rem 0.5rem;
  border-right: 0.2rem solid var(--sidebar-text-color);
  color: var(--sidebar-module-color);
  font-size: 2.7rem;
  cursor: pointer;
}

.question_icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  /* border: 1px solid black; */
}

.question_icon > p {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: var(--sidebar-text-color);
  margin: auto;
}

.notification-container{
 position: relative;
}

.notification-icon::before{
  content: "0";
  position: absolute;
  top:0.5rem ;
  right: 0;
  font-size: 0.9rem;
  color: var(--sidebar-bg-color);
  background-color: var(--delete-btn-color);
  border-radius: 50%;
  padding: 0rem 0.5rem;
  text-align: center;
}

.avatar-container {
  position: relative;
  cursor: pointer;
  margin: auto 1rem;
  display: flex;
  flex-direction: column;
  transition: all 0.5s linear;
}

.avatar-child-container{
  position: absolute;
  top: 7rem;
  right: -3rem;
  width: 15vw;
  background-color:var(--sidebar-bg-color);
  border-radius: 1rem;
  cursor: pointer;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.35);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.user-role{
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  margin-bottom: 0;
}

.user-name{
  font-size: 1.8rem;
  letter-spacing: 0.08rem;
  margin-bottom: 0;
}

.user-prevLogin-time{
  font-size: 1.2rem;
  letter-spacing: 0.10rem;
}

/* .avatar-child-container:last-child{
  display: flex;
  gap: 1rem;
  font-size: 1.6rem;
} */

.button-container button{
  font-size: 1.3rem;
  font-weight: 600;
  margin: 1rem 0.5rem;
}


/* Header dropdown for site change custom */

/* navbar css start */
.navbar-header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px 110px;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--grey-300, #d0d5dd);
  /* border-left: 1px solid red;
  border-right: 1px solid red; */
  background: var(--grey-50, #f9fafb);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  z-index: 1;
}
.navbar-header-left {
  font-family: sans-serif;
  font-weight: bold;
  margin-left: -69px !important;
}
.navbar-header-left > span {
  color: #764af0;
}
.navbar-header-right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 14px;
}


.notification_div {
  position: relative;
}
.search_slider:hover,
.question_icon_div:hover,
.notification_div:hover,
.brightness_div:hover {
  cursor: pointer;
}
/* navbar css end */
