.clickable-icon {
  background-color: #d4d0c8;
  border: 1px solid #a7a39e;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.clickable-icon:hover {
  background-color: #c1bdb3;
}

.disabled-icon {
  color: grey;
  cursor: not-allowed;
}


.overlay-canceled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5em; /* Adjust the font size as needed */
  color: red; /* Adjust the color as needed */
  opacity: 0.5; /* Adjust the transparency as needed */
  z-index: 9999; /* Ensure the overlay stays on top */
}


