/* Resualble css styling */
/* login start css */

.loginForm {
  width: 100%;
  padding: 20px 30px;
  max-width: 530px;
  min-width: 250px;
  border-radius: 10px;
  display: inline-block;
  background: #e6d0ffc9;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.25),
    0px 6px 12px 0px rgba(218, 198, 252, 0.8) inset,
    0px -6px 12px 0px rgba(234, 222, 253, 0.5) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.loginForm {
  width: 100%;
  padding: 20px 30px;
  max-width: 530px;
  min-width: 250px;
  border-radius: 10px;
  display: inline-block;
  background: #e6d0ffc9;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.25),
    0px 6px 12px 0px rgba(218, 198, 252, 0.8) inset,
    0px -6px 12px 0px rgba(234, 222, 253, 0.5) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.formImages {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

/* .form-group {
  margin-bottom: 20px;
  margin-right: 20px;
} */
.inputFieldIcon {
  width: 100%;
  position: relative;
}

.inputFieldIcon input {
  width: 100%;
  background: #fff;
  padding: 10rem;
}

.inputFieldIcon .iconPosition {
  color: var(--search-pl-color);
  font-size: 1.7rem;
  position: absolute;
  top: 0.8rem;
  right: 1.2rem;
}

.commonBtn {
  width: 100%;
  border: none;
  color: var(--table-bg-color);
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 0.5rem;
  padding: 0.9rem 0rem;
  background: var(--sidebar-module-color);
  box-shadow: 0px 0px 0.1rem 0px var(--table-title-color);
  letter-spacing: 0.1rem;
  /* transition: all ease 0.2s; */
}

.commonBtn:hover {
  color: var(--table-bg-color);
  background: var(--sidebar-hover-color);
  /* border: none; */
}

.smallBtn {
  width: 7%;
  min-width: 9.8rem;
  max-width: 42.5rem;
  box-shadow: none;
}

.cancelBtn {
  background: var(--table-bg-color);
  color: var(--table-title-color);
  border-radius: 0.6rem;
  border: 0.1rem solid var(--search-pl-color);
  transform: none;
}

.cancelBtn:hover {
  background: var(--table-bg-color);
  color: var(--table-title-color);
  box-shadow: 0rem 0rem 0.1rem 0.05rem var(--table-title-color);
}

.sinup-signin {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url("../assets/homedecor.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* .form-control {
  padding: 0.5rem 0.75rem !important;
} */

.login-btn {
  background-color: var(--login-btn-color);
  border: 1px solid var(--login-btn-border);
  color: #ffffff;
}

.login-inputs {
  padding: 1rem 3rem;
  border-radius: 3rem;
  font-size: 1.5rem;
}

.form-label {
  font-size: 1.5rem;
  line-height: 2.6rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
  color: black !important;
}

.star {
  color: var(--delete-btn-color);
}

/* login end css */

/* Breadcumb start css */
.colStyle {
  margin: 1rem 0;
  width: 100%;
  font-size: 1.65rem;
  letter-spacing: 0.08rem;
}

/* Breadcumb end css */

/* List Table view css start */
.table-wrapper-container {
  background-color: var(--table-bg-color);
  padding: 0.5rem;
}

.section-title {
  color: var(--table-title-color);
  font-size: 1.8rem;
  font-weight: 600;
  line-height: normal;
}

.section-title.form-section {
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  line-height: normal;
}

.ptag {
  border-bottom: none !important;
  padding-bottom: 0rem;
  padding: 1rem 1rem;
  margin-bottom: 0rem;
}

.form-title {
  color: var(--sidebar-module-color);
  font-size: 2rem;
  font-weight: 500;
}

.form-title-sibling {
  color: var(--new-row-color);
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}

.btn-close {
  font-size: 1.5rem;
}

.table-main-container {
  padding: 0;
  text-align: center;
  font-size: 1.5rem;
}

.tableContainer {
  --bs-table-bg: var(--table-head-color);
}

.tableContainer tr th:not(:last-child) {
  border-right: 1px solid var(--search-pl-color);
}

.spinner-border {
  --bs-spinner-width: 2.5rem;
  --bs-spinner-height: 2.5rem;
}

/* tooltip css start */
#tooltip {
  font-size: 1.3rem;
}

/* tooltip css end */

/* switch css start */
.form-switch>input:checked {
  background: var(--sidebar-module-color);
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"%3E%3Ccircle r="3" fill="%23fff"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  border-color: var(--sidebar-module-color);
  color: var(--sidebar-bg-color);
}

/* switch css end */

.datanotfound-center-text {
  font-weight: 600;
  color: var(--table-title-color);
  text-align: center;
}

/* List Table view css end */

/* pagination css start */

span.page-link,
a.page-link {
  /* background-color: var(--page-color); */
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 50%;
  color: var(--table-title-color);
}

.page-item.active {
  background: none;
}

.page-item.active span.page-link {
  background-color: var(--sidebar-text-color);
  background: none;
  border: none;
}

li.page-item.active {
  background-color: var(--sidebar-module-color);
  border-radius: 50%;
  border: none;
}

.pagination {
  gap: 0.25rem;
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.5rem;
  --bs-pagination-border-radius: 50%;
  cursor: pointer;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  background-color: var(--sidebar-module-color);
  color: var(--sidebar-bg-color);
}

.paginationLabel {
  color: var(--sidebar-module-color);
}

.pagination-text {
  font-size: 1.6rem;
  color: var(--input-color);
}

/* pagination css end */

/* delete css start */
.f1 {
  font-size: 1.9rem;
  font-weight: 500;
}

.deleteIcon {
  color: var(--delete-btn-color);
  font-size: 2rem;
  cursor: pointer;
}

/* delete css end */

/* edit css start */
.editIcon {
  color: var(--sidebar-text-color);
  font-size: 1.8rem;
  cursor: pointer;
}

/* edit css end */

/* view css start */
.viewIcon {
  color: var(--sidebar-text-color);
  font-size: 1.8rem;
  cursor: pointer;
}

/* view css end */

/* tab view css start*/
.tabview {
  margin-top: -0.9rem;
  /* font-size: 5rem; */
}

.tabview-1 {
  margin-top: 1.1rem;
}

.active {
  background-color: var(--sidebar-module-color) !important;
  color: var(--sidebar-bg-color) !important;
}

#justify-tab-example-tab-branches,
#justify-tab-example-tab-godownorshowroom,
#justify-tab-example-tab-companymanagement,
#justify-tab-example-tab-stocklocation,
#justify-tab-example-tab-statutory,
#justify-tab-example-tab-primarycontact,
#justify-tab-example-tab-bankinformation,
#justify-tab-example-tab-address,
#justify-tab-example-tab-receivegoods,
#justify-tab-example-tab-issuegoods,
#justify-tab-example-tab-tds,
#justify-tab-example-tab-tcs,
#justify-tab-example-tab-payment,
#justify-tab-example-tab-receipt,
#justify-tab-example-tab-contraentry,
#justify-tab-example-tab-purchasetarget,
#justify-tab-example-tab-salestarget,
#justify-tab-example-tab-personaldetails,
#justify-tab-example-tab-documentupload,
#justify-tab-example-tab-officialdetails,
#justify-tab-example-tab-contactaddress,
#justify-tab-example-tab-billpayment,
#justify-tab-example-tab-vendoradvance,
#justify-tab-example-tab-vat {
  border: none;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.08rem;
  border-right: 0.1rem solid #d0d5dd;
  border-left: 0.17rem solid #d0d5dd;
  border-top: 0.17rem solid #d0d5dd;
  background-color: #fff;
  padding: 8px 4rem;
  /* border-bottom: 1.7px solid #d0d5dd; */
}

#justify-tab-example-tabpane-branches,
#justify-tab-example-tabpane-godownorshowroom,
#justify-tab-example-tabpane-companymanagement,
#justify-tab-example-tabpane-stocklocation,
#justify-tab-example-tabpane-statutory,
#justify-tab-example-tabpane-primarycontact,
#justify-tab-example-tabpane-bankinformation,
#justify-tab-example-tabpane-address,
#justify-tab-example-tabpane-receivegoods,
#justify-tab-example-tabpane-issuegoods,
#justify-tab-example-tabpane-tds,
#justify-tab-example-tabpane-tcs,
#justify-tab-example-tabpane-payment,
#justify-tab-example-tabpane-receipt,
#justify-tab-example-tabpane-contraentry,
#justify-tab-example-tabpane-purchasetarget,
#justify-tab-example-tabpane-salestarget,
#justify-tab-example-tabpane-personaldetails,
#justify-tab-example-tabpane-documentupload,
#justify-tab-example-tabpane-officialdetails,
#justify-tab-example-tabpane-contactaddress,
#justify-tab-example-tabpane-bankinformation,
#justify-tab-example-tabpane-billpayment,
#justify-tab-example-tabpane-vendoradvance,
#justify-tab-example-tabpane-vat{
  background: none !important;
  box-shadow: none !important;
}

.nav-link {
  color: var(--dark-color);
  background-color: var(--section-color);
}

/* tab view css end*/

/* switch css start */
.deactivated {
  opacity: 0.5;
}

.deactivated_icons {
  display: none;
}

/* switch css end */

/* view page css start */
.viewpage-title {
  display: inline-block;
  width: 50%;
  font-size: 1.8rem;
  font-weight: 500;
}

.viewpage-value {
  font-size: 1.6rem;
  font-weight: normal;
}

.heading-title {
  display: inline-block;
  width: 40%;
}

.boldItem {
  font-weight: bold;
}

/* view page css end */

.form-check-input[type="checkbox"] {
  border: 0.7px solid rgb(54, 54, 54) !important;
}

/* form  css start*/
.invalid-feedback {
  font-size: 1.4rem;
}

/* form  css end*/

.viewpage-table-container {
  border-radius: 0.5rem;
  height: 25rem;
  max-height: 100vh;
  overflow: auto;
  padding: 0.5rem;
  box-shadow: 0px 0px 2px var(--table-title-color);
}

.viewpage-module {
  font-size: 1.5rem;
  font-weight: 500;
}

.viewpage-table-container::-webkit-scrollbar {
  width: 7px;
  /* Change the width of the scrollbar */
}

/* Style the scrollbar thumb (the draggable part of the scrollbar) */
.viewpage-table-container::-webkit-scrollbar-thumb {
  background: var(--section-color);
  border-radius: 10px;
  /* Round the edges of the thumb */
}

/* Style the scrollbar track on hover */
.viewpage-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* COA css start */
.modal-title {
  font-size: 2rem;
}

.form-check {
  font-size: medium;
}

/* COA css end */

/* manual journal css start */
.publish-status {
  color: var(--publish-color);
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.draft-status {
  color: var(--delete-btn-color);
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.table-delete {
  font-size: 2.3rem;
  margin: auto 0;
}

/* manual journal css end */

/* ledger css start */
.ledger-line {
  border-top: 2px solid var(--input-color);
}

/* ledger css end */

/* sale order css start */
.w-15 {
  width: 15%;
}

.from-SalesOrder {
  font-weight: 600;
}

.companyname {
  font-size: 2rem;
  font-weight: 500;
  font-style: italic;
}

.calculation-header {
  /* font-size: 1.9rem;
  font-weight: 500;
  margin-right: 1rem;
  width: 50%; */
  margin-right: 10rem;
}

/* .calculation-value {
  font-size: 1.6rem;
} */

.calculation-value.subtotal,
.calculation-value.grandtotal {
  font-weight: 700;
}

.suggestedCategory-container {
  font-size: 1.6rem;
  margin: 0rem 0rem 1.3rem 0rem;
  text-align: center;
}

.suggestedCategory {
  padding: 0.8rem;
  border: 0.2rem solid var(--sidebar-text-color);
  border-radius: 1.5rem;
  margin: 0rem 0.5rem;
  cursor: pointer;
}

.suggestedCategoryBtn {
  border-radius: 50%;
  padding: 0.5rem;
  text-align: center;
  margin: 0rem 0.5rem;
  cursor: pointer;
}

.uploaded-data {
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0px 4px;
}

.uploaded-data span a {
  /* color: var( --new-row-color); */
  font-size: 2.5rem;
  font-weight: 700;
}

.Approved-status {
  color: var(--publish-color) !important;
  /* or any color you prefer */
}

.Rejected-status {
  color: var(--delete-btn-color) !important;
  /* or any color you prefer */
}

.Close-status {
  color: var(--login-btn-color) !important;
  /* or any color you prefer */
}

.Draft-status {
  color: var(--sidebar-module-color) !important;
  /* or any color you prefer */
}

.css-b62m3t-container {
  font-size: 1.4rem;
}

.css-13cymwt-control {
  padding: 0.28rem;
}

.empty-feild {
  border-radius: 0.5rem;
  border: 0.1rem solid var(--delete-btn-color);
}

.customer-info {
  color: var(--unpaid-color);
  cursor: pointer;
}

/* sale order css end */

/* sale invoice css start */
.Unpaid-status {
  color: var(--unpaid-color) !important;
  /* or any color you prefer */
}

.question-icon {
  font-size: 2rem;
  font-weight: 500;
}

/* print screen css start */
.print-section {
  max-width: 1220px;
  margin: 0rem auto;
}

.print-section table {
  border-collapse: collapse;
}

.first-section {
  width: 100%;
  border-bottom: 2px solid black;
  margin-top: 1rem;
}

.saanvi_logo {
  padding: 0.5rem;
}

.saanvi-logo {
  width: 10rem;
  height: 8rem;
}

.saanvi-brand-container {
  padding: 0.5rem;
  width: 65%;
}

.saanvi-brand {
  font-size: 1.5rem;
}

.saanvi-deails {
  font-size: 1rem;
  letter-spacing: 0.05rem;
  line-height: 1.4;
  margin-bottom: 0;
}

.saanvi-gst {
  font-size: 1rem;
  font-weight: 600;
}

.saanvi-deails.so {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0rem;
  width: 45%;
}

.customer-deails {
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  line-height: 1.4;
  margin-bottom: 0;
  display: inline-block;
  width: 45%;
  margin-bottom: 0rem;
}

.saanvi-contact-details {
  padding: 0.5rem;
  width: 35%;
  margin-right: 1rem;
}

.printButton {
  position: relative;
  right: 0;
  background-color: #f59e0b;
  color: #f9fafb;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  padding: 0.7rem;
  border-radius: 0.4rem;
}

.saanvi-details-section {
  font-size: 1rem;
}

.saanvi-contact {
  margin-left: 0.8rem;
}

.second-section {
  width: 100%;
  border-bottom: 2px solid black;
}

.gst-section {
  padding: 1rem;
}

.gst {
  margin: 0rem 7rem 0rem 0rem;
}

.invoiceno-section {
  padding: 1rem;
  text-align: end;
}

.third-section {
  width: 100%;
  border-bottom: 2px solid black;
}

.customer-details-section {
  width: 100%;
  text-align: center;
  padding: 0.2rem;
}

.four-section {
  width: 100%;
  border-bottom: 2px solid black;
}

.address-section {
  width: 100%;
  display: flex;
  /* gap: rem; */
}

.first-col {
  width: 50%;
  padding: 0.5rem;
  text-align: start;
  border-right: 2px solid black;
}

.second-col {
  width: 50%;
  padding: 0.5rem;
  text-align: start;
}

.address-section p {
  margin-bottom: 0;
}

.btitle {
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  font-weight: 400;
  width: 35%;
  margin-bottom: 0;
}

.btitle.cd {
  text-align: start;
  width: 40%;
}

.desc {
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  text-align: end;
}

.five-section {
  width: 100%;
}

.payment-section {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.six-section {
  width: 100%;
  border-bottom: 2px solid black;
}

.table-head-color {
  --bs-table-bg: var(--print-table-head-color);
  --bs-table-color: var(--table-bg-color);
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

.tablebody {
  font-size: 1rem;
  border-bottom: 0.01rem solid black;
}

.print-total {
  text-align: end !important;
  font-weight: 600;
}

.tableborder,
.tableborder tr th,
.tableborder td {
  border: 0.1px solid black;
  text-align: center;
}

.seven-section,
.eight-section {
  width: 100%;
  border-bottom: 0.1rem solid black;
}

.saanvi-info-container {
  display: flex;
}

.saanvi-info-container div {
  width: 100%;
}

.saanvi-info {
  position: relative;
  border-inline: 0.1rem solid black;
}

.saanvi-info+div {
  text-align: center;
}

.bank-details {
  text-align: center;
  font-size: 1.4rem;
  border-bottom: 0.1rem solid black;
  padding: 0.5rem;
}

.bank-info {
  padding: 0.5rem;
  border-bottom: 0.1rem solid black;
}

.tandc {
  padding: 0.7rem;
  text-align: center;
}

.tandc textarea {
  font-size: 1rem !important;
}

.saanv-certificate-section {
  padding: 0.7rem;
  text-align: center;
  border-bottom: 0.1rem solid black;
  margin-bottom: 0rem;
}

.certified {
  display: block;
  font-size: 1.1rem;
  letter-spacing: 0.04rem;
}

.certified-section {
  font-size: 1.5rem;
  font-weight: 600;
}

.signature-section {
  height: 10rem;
}

.authorized {
  width: 100%;
  position: absolute;
  bottom: 0rem;
  padding: 0.5rem;
  text-align: center;
  border-top: 0.1rem solid black;
  margin-bottom: 0rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.authorized.sign {
  top: 8rem;
}

.newpage {
  page-break-before: always;
}

.ten-section {
  width: 100%;
  border-bottom: 2px solid black;
}

/* print screen css end */

/* sale invoice css end */

/* report css start */

.column-select-icon {
  border: 0.7px solid #dee2e6;
  border-radius: 3px;
  padding: 0rem 0.25rem;
  font-size: 2.3rem;
  margin-top: 10.7px;
}

.bg-red {
  background-color: red !important;
}

/* report css end */

/* home page css start */
.welcome-page {
  /* width: 94vw; */
  height: 86vh;
  background-image: url("../assets/vs background.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.welcome-page h1 {
  position: absolute;
  left: 31rem;
  bottom: 5rem;
  font-size: 8rem;
  font-weight: 600;
  font-style: oblique;
  letter-spacing: 1.2rem;
  line-height: 1.6;
  color: var(--sidebar-module-color);
}

/* home page css end */

/* dashboard page css start */
.card-stats {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  cursor: pointer;
}

.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-big {
  font-size: 2.5rem;
  text-align: center;
  color: var(--main-bg-color);
  padding: 1rem;
  border-radius: 1.5rem;
  align-self: flex-start;
}

.card-title {
  font-size: 2rem;
  color: var(--input-color);
}

.count {
  font-size: 3rem;
  font-weight: 550;
  margin-top: 1rem;
}

.user {
  background-color: var(--user-color);
}

.order {
  background-color: var(--order-color);
}

.invoice {
  background-color: var(--invoice-color);
}

.audit {
  background-color: var(--audit-color);
}

.card-Title {
  color: var(--sidebar-module-color);
  font-size: 2.5rem;
}

#monthSelect {
  padding: 1rem 3rem !important;
  border-radius: 1rem;
}

/* dashboard page css end */

/* purchase order css start */
.bill_logo {
  max-width: 50%;
  max-height: 100px;
  border-radius: 5px;
  object-fit: contain;
}

/* purchase order css end */