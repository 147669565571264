@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
/* Base Rule */

@font-face {
  font-family: "SF-pro", sans-serif;
  src: url("../public/San-Francisco-Pro-Fonts-master/SF-Pro-Display-Regular.otf"),
    url("../public/San-Francisco-Pro-Fonts-master/SF-Pro-Display-Light.otf"),
    url("../public/San-Francisco-Pro-Fonts-master/SF-Pro-Display-Bold.otf");

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SF-pro", sans-serif;

}

html {
  font-size: 62.5%;
  /* 1rem = 10px */
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--bg-color);
}

h1 {
  font-size: 5.4rem;
  line-height: 1.1;
  font-weight: bold;
}


li,
input,
textarea {
  font-size: 1.8rem;
  line-height: 1.56;
  letter-spacing: 0.1rem;
  word-spacing: 0.06rem;
  border: none;
}

a {
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  color: var(--btn-color);
  text-decoration: inherit;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul li {
  list-style: none;
}

option {
  font-size: 1.6rem;
}

.no-background {
  background-color: whitesmoke !important;
}

p {
  margin-bottom: 0.5rem !important;
}