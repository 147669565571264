/* login start css */
.auth-page {
  padding: 25px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px 1rem;
}

.auth-page::after {
  content: "⚡Powered By Venturesathi";
  font-size: 1.3rem;
  position: absolute;
  bottom: 0rem;
}

.login-image-container,
.login-image-container.Sub-Dealer,
.login-image-container.Franchise {
  position: relative;
  height: 100%;
  /* Set the height as needed */
  margin: auto;
  border-radius: 2rem;
  background-image: url("../assets/company.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* Align content at the bottom */
  padding-bottom: 2rem;
  /* Add some padding at the bottom */
  opacity: 1;
}

.login-image-container.Sub-Dealer {
  background-image: url("../assets/sub dealer.webp");
}

.login-image-container.Franchise {
  background-image: url("../assets/franches.webp");
}

.login-image-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2));
  border-radius: 2rem;
}

.login-text {
  width: 100%;
  padding-left: 8rem;
  font-size: larger;
  color: #ffffff;
  z-index: 1;
  /* Ensure text appears above the pseudo-element */
}

.login-text h1 {
  font-size: 3rem;
  font-weight: 700;
}

.login-text h2 {
  font-size: 2rem;
  font-weight: 400;
}

.swan-img {
  width: 60%;
  height: auto;
  margin: 10rem auto 25px auto;
  padding-top: 2rem;
}

.login-form-container {
  padding: 2rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  height: 100%;
  border-radius: 2rem;
}

.text-right {
  text-align: right;
  font-weight: 600;
}

.login-heading {
  text-align: center;
  font-size: 3rem;
  height: 20%;
}

.form-container-login {
  padding: 2rem;
  width: 70%;
  margin: auto;
  /* border: 1px solid blue; */
  padding-top: 4rem;
}

.badge-container {
  width: 93%;
  /* border: 1px solid #F8DDDD; */
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0rem 1rem 0rem;
  border-radius: 3rem;
  background-color: var(--badge-bg-color);
  /* border: 1px solid blue; */
}

.badges {
  font-size: 1.5rem;
  padding: 1rem 3rem;
  border-radius: 3rem;
  cursor: pointer;
  /* color: black; */
  letter-spacing: 0.05rem;
  background-color: var(--badges-bg-color) !important;
}

.support {
  color: #4285f4;
  cursor: pointer;
}

.badge-nav {
  color: black;
  cursor: pointer;
  font-size: 1.5rem;
  padding-left: 0.3rem;
}



/* OTPModal.css */

/* Style for OTPInput container */
.otp-input-container {
  display: flex;
  justify-content: space-between;
}

/* Style for each OTP input box */
.otp-input-container input {
  width: 40px !important;
  width: 40px !important;
  text-align: center;
  /* Center-align text inside input box */
  font-size: 2rem;
  /* Increase font size */
  margin-right: 10px;
  /* Add right margin between input boxes */
  margin-bottom: 10px;
  /* Add bottom margin */
  border: 1px solid rgb(255, 227, 104);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}


/* login end css */

/* forms-css-start */

.form-container {
  background-color: #f9fafb;
  border-radius: 10px;
  padding: 1rem;
}

.form-lable-name {
  color: var(--grey-800, #1d2939) !important;
  margin: 10px;
}

.left-arrow {
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 2rem;
  color: var(--dark-color);
}

.star {
  color: #d83b39;
}

.custom-cursor {
  cursor: pointer;
}

.form-control,
.form-select {
  padding: 1rem 1.3rem !important;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

.tab-margin {
  margin-bottom: 1.2rem;
}

/* forms-css-end */

.items-frequent {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 25px 0px;
  display: grid;
  padding: 15px 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  gap: 15px 20px;
  height: 250px;
  /* Set a specific height for the scrollable area */
  overflow: auto;
  /* Enable scrolling */
}

.items-frequent-row {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 8px 4px;
  border-radius: 8px;
}

.dr-logo-heading {
  width: 100%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.img-logo,
.inv-info {
  width: 24%;
}

.img-logo>img {
  width: 70%;
  height: 70%;
}

.company-information {
  width: 50%;
  text-align: left;
  padding-left: 1.5px;
}

.bill-ship-info,
.tandcdiv {
  border: 0.5px solid black;
}

.bill-ship-info div {
  width: 50%;
  padding: 5px 7px;
  border-right: 0.5px solid black;
}

.bill-ship-info div:last-child {
  border: none;
}

.th-color {
  background-color: #42536d !important;
  color: whitesmoke !important;
  padding: 4px;
  text-align: center !important;
}

.table-color td {
  padding: 2px;
  border-right: 1px solid rgb(131, 131, 131);
  border-left: 1px solid rgb(131, 131, 131);
  border-bottom: none !important;
  border-top: none !important;
  text-align: center;
}

.tddata-right {
  display: flex;
  justify-content: end;
  align-items: center;
  font-weight: 600 !important;
  border-right: none !important;
  /* border-left: none !important; */
}

.tddata-left {
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: 600 !important;
  border-right: none !important;
  /* border-left: none !important; */
}

.font-bold {
  font-weight: 600;
}

.td-top-border {
  border-top: 1px solid black;
}

.table-row-bg {
  border: 1px solid black;
  background-color: #EAECf0 !important;
}

.tandcdiv>div:first-child {
  width: 50%;
  border-right: 0.5px solid black;
}

.tandcdiv>div:last-child {
  width: 50%;

}

.bb {
  border-bottom: 1px solid black;
}

/* report css start */
.reportTable>.table-responsive {
  height: 70vh;
}

/* report css end */