/* Theme Rule */
:root {
  --sidebar-bg-color: #fafafa;
  --sidebar-text-color: #344054;
  /* --sidebar-module-color: #ffa000; */
  /* --sidebar-hover-color: #d98800; */
  --sidebar-module-color: #0065CB;
  --sidebar-hover-color: #0065CB;
  --sidebar-ho-text-color: #f2f4f7;
  --page-color: #f9dbb5;
  --delete-btn-color: #e70e0a;
  --table-head-color: #e3e7eb;
  --table-bg-color: #fafafa;
  --table-title-color: #1d2939;
  --main-bg-color: #eaecf0;
  --new-row-color: #114388;
  --publish-color: #0ebe08;
  --blue-color: #3342ff;
  --unpaid-color: #29b6f6;
  --print-table-head-color: #42536d;

  --dark-color: #242424;
  --helper-color: #99b9ff;
  --input-color: #555;
  --login-color: linear-gradient(to bottom,
      #ffd9a4,
      #ffcda1,
      #ffc2a0,
      #ffb7a2,
      #feada5);
  --login-btn-color: #0065CB;

  /* --badge-bg-color: #fff2dc; */
  /* --badges-bg-color: #f59e0b; */
  --badge-bg-color: #CCE0F5;
  --badges-bg-color: #0065CB;

  --search-pl-color: #555;
  --pagination-no-color: #d6bbfb;
  --pagination-no-active-color: #5119ed;

  --user-color:#2A3663;
  --order-color:#798645;
  --invoice-color:#629584;
  --audit-color:#825B32;
}