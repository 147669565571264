/* Invoice Container */
.invoice-container {
    width: 21cm;
    height: 29.7cm;
    margin: 0 auto;
    padding: 2cm;
    font-family: sans-serif;
    /* Add borders for the entire invoice container */
    border: 1px solid #000000;
  }
  
  /* Invoice Header */
  .invoice-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2cm;
    /* Add a bottom border for the header section */
    border-bottom: 1px solid #ddd;
  }
  
  .company-info {
    flex: 1;
  }
  
  .company-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .company-address,
  .company-contact {
    margin-bottom: 10px;
  }
  
  .company-address p,
  .company-contact p {
    font-size: 12px;
    line-height: 1.5;
  }
  
  .invoice-details {
    flex: 1;
    text-align: right;
  }
  
  .invoice-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .invoice-number {
    font-size: 14px;
  }
  
  /* Customer Details */
  .customer-details {
    display: flex;
    justify-content: space-between;
   
    margin: 2cm 0 2cm 0;

    /* Add a top and bottom border for the customer details section */
    border-top: 1px solid #363636;
    border-bottom: 1px solid #363636;
  }
  
  .bill-to,
  .payment-info {
    flex: 1;
  }
  
  .bill-to h3,
  .payment-info h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .bill-to p,
  .payment-info p {
    font-size: 12px;
    line-height: 1.5;
  }
  
  /* Invoice Table */
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2cm;
  }
  
  .invoice-table th,
  .invoice-table td {
    border: 1px solid #aaaaaa; /* Maintain borders for individual cells */
    padding: 8px;
    font-size: 12px;
  }
  
  .invoice-table th {
    background-color: #f2f2f2; /* Light gray background for headers */
    text-align: left;
    font-weight: bold;
  }
  
  /* Table Data Styling */
  .invoice-table td {
    padding-left: 15px; /* Add left padding for data cells */
  }
  
  /* Invoice Total Row */
  .invoice-table tfoot tr {
    font-weight: bold;
  }
  
  /* Terms and Conditions */
  .terms-and-conditions {
    font-size: 10px;
    line-height: 1.5;
    /* Add a top border for the terms and conditions section */
    border-top: 1px solid #303030;
    margin-bottom: 10px;
  }
  
  .terms-and-conditions p:nth-child(2) {
    margin-bottom: 10px;
  }
  